// 导出弹窗
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '导出时间' : '导出时间'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="导出时间:" name="timeValue">
            <a-range-picker
              v-model:value="form.timeValue"
              :disabledDate="getDisabledDate"
              format="YYYY/MM/DD HH:mm:ss"
              valueFormat="YYYY/MM/DD HH:mm:ss"
              @change="daochuChange"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as batteryApi from '@/api/rnt/battery'
import moment from 'moment'
export default {
  name: 'export',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 编辑弹窗表单验证规则
      rules: {
        timeValue: [
          {
            required: true,
            message: '请选择时间',
            type: 'array',
            trigger: 'blur'
          }
        ]
      },
      form: {
        timeValue: null
      }
      // 定义时间
    }
  },

  mounted() {
    // this.init()
  },
  methods: {
    moment,
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    // 不可选日期
    getDisabledDate(current) {
      return current > moment().subtract(0, 'day')
      // return current && current < moment().endOf('day')
    },
    panelChange(value) {
      console.log(value)
    },
    // 点击改变时间
    daochuChange(date, dateStrings) {
      // console.log('From: ', date[0], ', to: ', date[1])
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
      console.log(date, dateStrings)
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.modulesArray) {
            this.form.modules = this.form.modulesArray.join(',')
          }
          if (this.timeValue && typeof this.timeValue === 'object') {
            this.timeValue = this.timeValue.format('MMMM Do YYYY, h:mm:ss a')
          }
          batteryApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
