import request from '@/utils/request'

// api地址
const api = {
  page: '/rnt/batteryGpsLast/page',
  all: 'rnt/batteryGpsLast'
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function all() {
  return new Promise((resolve, reject) => {
    request.get(api.all).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
