 <!-- 电池监控-->
<template>
  <div class="ud-body">
    <a-row>
      <!-- 电池编号 -->
      <a-col style="border: 3px solid #ddeef6" :span="18">
        <a-col
          class="bianhao"
          style="border-bottom: 3px solid #ddeef6"
          :span="24"
        >
          <a-row>
            <a-col :span="20">
              <p class="title-span">电池编号：{{ batteryBmsInfo.deviceId }}</p>
              <p>更新时间：{{ batteryBmsInfo.updateTime }}</p>
            </a-col>
            <a-col :span="2">
              <a-button type="primary" @click="exportBtn">
                <template #icon>
                  <RotateRightOutlined />
                </template>
                导出
              </a-button>
            </a-col>
            <a-col :span="2">
              <a-button type="primary" @click="shuaxin">
                <RedoOutlined />
                刷新
              </a-button>
            </a-col>
          </a-row>
          <a-row class="menu" :gutter="16">
            <a-col class="gutter-row" :span="6">
              <div class="battery-img">
                <div class="header"></div>
                <div class="battery">
                  <div class="battery-color"></div>
                  <div
                    class="batteryNull"
                    :style="'width:' + (100 - batteryBmsInfo.soc) + '%;'"
                  ></div>
                </div>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="3" flex>
              <div class="gutter-box">
                soc
                <p class="color">{{ batteryBmsInfo.soc }}%</p>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="3">
              <div class="gutter-box">
                电池状态
                <p class="color">
                  {{
                    batteryBmsInfo.workStatus === '0'
                      ? '放电中'
                      : batteryBmsInfo.workStatus === '1'
                      ? '充电中'
                      : '闲置'
                  }}
                </p>
              </div>
            </a-col>
            <a-col class="gutter-row" :span="3">
              <div class="gutter-box">
                告警状态
                <p class="color">{{ batteryBmsInfo.bmsAlarmList }}</p>
                <!-- <p
                  v-else
                  v-for="(text,index) in batteryBmsInfo.bmsAlarmList"
                  :key="index"
                  >{{ text }}</p
                > -->
              </div> </a-col
            ><a-col class="gutter-row" :span="3">
              <div class="gutter-box">
                GSM信号
                <p class="color">{{ batteryBmsInfo.gsm }}</p>
              </div> </a-col
            ><a-col class="gutter-row" :span="3">
              <div class="gutter-box">
                GPM里程
                <p class="color">
                  {{ Number(batteryBmsInfo.miles).toFixed(2) }}km
                </p>
              </div> </a-col
            ><a-col class="gutter-row" :span="3">
              <div class="gutter-box">
                速度
                <p class="color">{{ batteryBmsInfo.speed }}km/h</p>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-row
                :gutter="[24, 16]"
                :wrap="true"
                :justify="center"
                :type="flex"
                :align="middle"
              >
                <a-col :span="6">
                  单充电保护电压
                  <p class="color" v-if="batteryBmsInfo.cpbv">
                    {{ batteryBmsInfo.cpbv }}mV
                  </p>
                </a-col>
                <a-col :span="6">
                  充电开关
                  <p class="color">
                    {{ batteryBmsInfo.chon === '0' ? '关闭' : '开启' }}
                  </p>
                </a-col>
                <a-col :span="6">
                  电池类型
                  <p class="color">
                    {{ batteryBmsInfo.batteryType === '0' ? '三元' : '钛锂' }}
                  </p>
                </a-col>
                <a-col :span="6">
                  mos温度
                  <p class="color" v-if="batteryBmsInfo.tcT">{{ batteryBmsInfo.tcT }}&#8451;</p>
                </a-col>
              </a-row>
              <a-row
                :gutter="[24, 16]"
                :wrap="true"
                :justify="center"
                :type="flex"
                :align="middle"
              >
                <a-col :span="6">
                  单放电保护电压
                  <p class="color" v-if="batteryBmsInfo.dpbv">
                    {{ batteryBmsInfo.dpbv }}mV
                  </p>
                </a-col>
                <a-col :span="6">
                  放电开关
                  <p class="color">
                    {{ batteryBmsInfo.dhon === '0' ? '关闭' : '开启' }}
                  </p>
                </a-col>
                <a-col :span="6">
                  电池串数
                  <p class="color" v-if="batteryBmsInfo.bcst">
                    {{ batteryBmsInfo.bcst }}串
                  </p>
                </a-col>
                <a-col :span="6">
                  电池温度1
                  <p class="color" v-if="batteryBmsInfo.tcB1">
                    {{ batteryBmsInfo.tcB1 }}&#8451;
                  </p>
                </a-col>
              </a-row>
              <a-row
                :gutter="[24, 16]"
                :wrap="true"
                :justify="center"
                :type="flex"
                :align="middle"
              >
                <a-col :span="6">
                  单充电恢复电压
                  <p class="color" v-if="batteryBmsInfo.srbv">
                    {{ batteryBmsInfo.srbv }}mV
                  </p>
                </a-col>
                <a-col :span="6">
                  充电保护
                  <p class="color">
                    {{
                      batteryBmsInfo.cpStatus === '0'
                        ? '正常'
                        : batteryBmsInfo.cpStatus === '1'
                        ? '硬控保护'
                        : '软控保护'
                    }}
                  </p>
                </a-col>
                <a-col :span="6">
                  循环次数
                  <p class="color" v-if="batteryBmsInfo.bxhc">
                    {{ batteryBmsInfo.bxhc }}次
                  </p>
                </a-col>
                <a-col :span="6">
                  电池温度2
                  <p class="color" v-if="batteryBmsInfo.tcB2">
                    {{ batteryBmsInfo.tcB2 }}&#8451;
                  </p>
                </a-col>
              </a-row>
              <a-row
                :gutter="[24, 16]"
                :wrap="true"
                :justify="center"
                :type="flex"
                :align="middle"
              >
                <a-col :span="6">
                  单放电恢复电压
                  <p class="color" v-if="batteryBmsInfo.drbv">
                    {{ batteryBmsInfo.drbv }}mV
                  </p>
                </a-col>
                <a-col :span="6">
                  放电保护
                  <p class="color">
                    {{
                      batteryBmsInfo.dpStatus === '0'
                        ? '正常'
                        : batteryBmsInfo.cpStatus === '1'
                        ? '硬控保护'
                        : '软控保护'
                    }}
                  </p>
                </a-col>
                <a-col :span="6">
                  标称容量
                  <p class="color" v-if="batteryBmsInfo.bcrl">
                    {{ batteryBmsInfo.bcrl }}Ah
                  </p>
                </a-col>
                <a-col :span="6">
                  ext温度1
                  <p class="color" v-if="batteryBmsInfo.tcExt1">
                    {{ batteryBmsInfo.tcExt1 }}&#8451;
                  </p>
                </a-col>
              </a-row>
              <a-row
                :gutter="[24, 16]"
                :wrap="true"
                :justify="center"
                :type="flex"
                :align="middle"
              >
                <a-col :span="6">
                  总充电保护电压
                  <p class="color" v-if="batteryBmsInfo.acpbv">
                    {{ batteryBmsInfo.acpbv }}V
                  </p>
                </a-col>
                <a-col :span="6">
                  短路保护
                  <p class="color">
                    {{ batteryBmsInfo.scProtect === '0' ? '正常' : '保护' }}
                  </p>
                </a-col>
                <a-col :span="6">
                  放电电流
                  <p class="color" v-if="batteryBmsInfo.dPowerA">{{ batteryBmsInfo.dPowerA }}A</p>
                </a-col>
                <a-col :span="6">
                  ext温度2
                  <p class="color" v-if="batteryBmsInfo.txExt2">
                    {{ batteryBmsInfo.txExt2 }}&#8451;
                  </p>
                </a-col>
              </a-row>
              <a-row
                :gutter="[24, 16]"
                :wrap="true"
                :justify="center"
                :type="flex"
                :align="middle"
              >
                <a-col :span="6">
                  总放电保护电压
                  <p class="color" v-if="batteryBmsInfo.adpbv">
                    {{ batteryBmsInfo.adpbv }}V
                  </p>
                </a-col>
                <a-col :span="6">
                  均衡状态
                  <p class="color">{{ batteryBmsInfo.balanceState }}</p>
                </a-col>
                <a-col :span="6">
                  充电电流
                  <p class="color" v-if="batteryBmsInfo.cPowerA">{{ batteryBmsInfo.cPowerA }}A</p>
                </a-col>
                <a-col :span="6">
                  均衡类型
                  <p class="color">
                    {{
                      batteryBmsInfo.balanceType === '0'
                        ? '无均衡'
                        : batteryBmsInfo.balanceType === '1'
                        ? '被动'
                        : '主动'
                    }}
                  </p>
                </a-col>
              </a-row>
              <a-row
                :gutter="[24, 16]"
                :wrap="true"
                :justify="center"
                :type="flex"
                :align="middle"
              >
                <a-col :span="6">
                  总充电恢复电压
                  <p class="color" v-if="batteryBmsInfo.asrbv">
                    {{ batteryBmsInfo.asrbv }}V
                  </p>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <span>map</span><span class="map-title">设备号:</span
              ><span class="map-value">{{ batteryBmsInfo.deviceId }}</span
              ><span class="map-value">{{
                batteryBmsInfo.offline === '1' ? '设备在线' : '设备离线'
              }}</span
              ><span class="map-title">更新时间:</span
              ><span class="map-value">{{ batteryBmsInfo.gpsTime }}</span>
              <p id="container"></p>
            </a-col>
          </a-row>
        </a-col>
        <!-- 电池单体电压 -->
        <a-col :span="24" class="dianya">
          <a-row>
            <a-col class="title-span" :span="22"> 电池单体电压 </a-col>
            <a-col :span="2">img极值</a-col>
          </a-row>
          <a-row class="lis" :gutter="[24, 16]" :wrap="true">
            <a-col
              :span="4"
              v-for="(dianyaValue, index) in dianyas"
              :key="index"
            >
              <div class="battery-dianya">
                <div class="battery-dianya-header"></div>
                <div class="battery-dianya-body flex">
                  {{ dianyaValue}}
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-col>
      <!-- 统计图 -->
      <a-col :span="6">
        <a-form-item label="时间跨度:">
          <a-select default-Value="3小时" allow-clear @change="timeChange">
            <a-select-option
              v-for="time in timeSpans"
              :key="time.name"
              :value="time.value"
            >
              {{ time.value }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 电压曲线 -->
        <div id="voltage" style="height: 300px"></div>
        <!-- 电流曲线 -->
        <div id="current" style="height: 300px"></div>
        <!-- 温度 -->
        <div id="wendu" style="height: 300px"></div>
      </a-col>
      <!-- 运营信息 -->
      <!-- <a-col :span="24">
        <a-card :bordered="true">
          <a-row>
            <a-col :span="20"> 电池单体电压 </a-col>
            <a-col :span="2">
              <a-button type="primary" shape="round">租赁</a-button>
            </a-col>
            <a-col :span="2">
              <a-button type="primary" shape="round">换电</a-button>
            </a-col>
          </a-row>
          <ud-pro-table
            ref="table"
            row-key="batteryId"
            :datasource="datasource"
            :columns="columns"
            :scroll="{ x: 'max-content' }"
          >
            <template #protocolType="{ record }">
              <span>
                {{ typeProtocol(record.protocolType) }}
              </span>
            </template>
          </ud-pro-table>
        </a-card>
      </a-col> -->
      <!-- 导出 -->
      <batteryExport v-model:visible="exportEdit"></batteryExport>
    </a-row>
  </div>
</template>

<script>
// import { SmileTwoTone } from '@ant-design/icons-vue'
import { reloadPageTab } from '@/utils/page-tab-util.js'
import * as echarts from 'echarts'
import batteryExport from '@/components/export/index'
import { RotateRightOutlined, RedoOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import * as batteryBmsApi from '@/api/rnt/batteryBms.js'
import * as batteryGpsLastApi from '@/api/rnt/batteryGpsLast.js'
import AMap from 'AMap'
var map, marker
export default defineComponent({
  components: {
    RotateRightOutlined,
    RedoOutlined,
    batteryExport
  },
  data() {
    return {
      id: 0, // 电池id
      // 当前的系统时间
      systemDateTime: null,
      // echarts图表的显示时间
      dianliuTime: null,
      // 是否显示导出编辑弹窗
      exportEdit: false,
      timeSpans: [
        {
          name: '三小时',
          value: '3小时'
        },
        {
          name: '一小时',
          value: '1小时'
        },
        {
          name: '一点半小时',
          value: '1.5小时'
        },
        {
          name: '两小时',
          value: '2小时'
        }
      ],
      // items: [
      //   {
      //     num: 'BT30720000000000',
      //     time: '2021-10-29',
      //     soc: '80'
      //   }
      // ],
      batteryBmsInfo: {},
      // 表格列配置
      columns: [
        {
          title: '编号',
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '电池编号',
          dataIndex: 'batteryCode',
          sorter: true
        },
        {
          title: '电池品牌',
          dataIndex: 'brand',
          sorter: true
        },
        {
          title: '设备编号',
          dataIndex: 'deviceCode',
          sorter: true
        },
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '电池协议',
          dataIndex: 'protocolType',
          sorter: true,
          slots: { customRender: 'protocolType' }
        },
        {
          title: '运营商名称',
          dataIndex: 'operatorName',
          sorter: true
        },
        {
          title: '定位状态',
          dataIndex: 'pointName',
          sorter: true,
          slots: { customRender: 'pointName' }
        },
        {
          title: '所属租赁点名称',
          dataIndex: 'pointName',
          sorter: true,
          slots: { customRender: 'pointName' }
        },
        {
          title: '仓库名称',
          dataIndex: 'warehouseName',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ]
      // 电压值
      // dianyas: [
      //   {
      //     name: '',
      //     value: '3.678'
      //   },
      //   {
      //     name: '',
      //     value: '3.666'
      //   },
      //   {
      //     name: '',
      //     value: '3.688'
      //   },
      //   {
      //     name: '',
      //     value: '3.643'
      //   },
      //   {
      //     name: '',
      //     value: '3.678'
      //   },
      //   {
      //     name: '',
      //     value: '3.678'
      //   },
      //   {
      //     name: '',
      //     value: '3.678'
      //   },
      //   {
      //     name: '',
      //     value: '3.678'
      //   }
      // ]
    }
  },
  computed: {
    dianyas() {
      if (!this.batteryBmsInfo.voltageValue) {
        return []
      }
      return this.batteryBmsInfo.voltageValue.split('|').map((item) => {
        return (Number(item) / 1000).toFixed(3)
      })
    }
  },
  mounted() {
    this.init()
    this.initMapData()
    this.getBatteryBmsInfo()
    this.getBatteryGpsLastInfo()
  },
  methods: {
    getBatteryBmsInfo() {
      batteryBmsApi
        .page({ deviceId: this.$route.query.deviceId })
        .then((res) => {
          if (res.code === 0) {
            this.batteryBmsInfo = res.data[0]
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    getBatteryGpsLastInfo() {
      batteryGpsLastApi
        .page({ deviceId: this.$route.query.deviceId })
        .then((res) => {
          if (res.code === 0) {
            const longitude = res.data[0].longitude
            const latitude = res.data[0].latitude
            // this.batteryBmsInfo = res.data
            this.setMarker(longitude, latitude)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    // 获取当前时间
    timeChange(value) {
      console.log(value)
    },
    initMapData() {
      map = new AMap.Map('container', {
        center: [118.08923, 24.479406],
        resizeEnable: true,
        zoom: 10
      })
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], function () {
        map.addControl(new AMap.ToolBar())
        map.addControl(new AMap.Scale())
      })
      // this.getMarker()
      // this.setMarker()
    },
    // 获取点位信息并加载到map
    setMarker(longitude, latitude) {
      const zoom = map.getZoom()
      const sizeNum = zoom ? (Number(zoom) / 2) * 6 : 25
      const chargeCabinetIcon = new AMap.Icon({
        size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
        image: '/static/battery.png', // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
      })

      // const lnglat = ['118.08923', '24.479406']
      const lnglat = [String(longitude), String(latitude)]
      console.log('lnglat', lnglat)
      marker = new AMap.Marker({
        position: lnglat,
        icon: chargeCabinetIcon,
        extData: {
          type: 'chargeCabinet'
        }
      })
      map.add(marker)
      map.setFitView()
    },
    // 刷新
    shuaxin() {
      reloadPageTab()
    },
    init() {
      // 更新时间
      setInterval(() => {
        this.systemDateTime = new Date().toLocaleString()
      }, 1000)
      // 获取当前时间 用于echarts图表
      this.dianliuTime = new Date().toLocaleDateString()
      // 基于准备好的dom，初始化echarts电压实例
      var chartVoltage = echarts.init(
        document.getElementById('voltage'),
        'light'
      )
      const voltageDataX = (function () {
        let now = new Date()
        const res = []
        let len = 10
        while (len--) {
          res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
          now = new Date(+now - 2000)
        }
        return res
      })()
      const voltageDataY = (function () {
        const res = []
        let len = 0
        while (len < 10) {
          res.push(+(Math.random() * 10 + 5).toFixed(1))
          len++
        }
        return res
      })()
      // 绘制电压图表配置项
      var optionFirst = {
        title: {
          text: '实时电压曲线' + '(' + this.dianliuTime + ')',
          textStyle: {
            color: '#1879b9'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: voltageDataX
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        series: [
          {
            name: '电压',
            // data: [820, 932, 901, 934, 1290, 1330, 1320],
            data: voltageDataY,
            type: 'line',
            areaStyle: {}
          }
        ]
        // dataZoom: [
        //   {
        //     type: 'slider',
        //     show: true,
        //     xAxisIndex: [0],
        //     start: 1,
        //     end: 60
        //   }
        // ]
      }
      // 将配置项设置给echarts实例对象
      chartVoltage.setOption(optionFirst)
      // 监听window窗口大小变化的事件
      window.onresize = function () {
        // 调用echarts实例对象的resize方法
        chartVoltage.resize()
        chartCurrent.resize()
        chartTemp.resize()
      }

      setInterval(function () {
        const axisData = new Date().toLocaleTimeString().replace(/^\D*/, '')
        voltageDataY.shift()
        voltageDataY.push(+(Math.random() * 10 + 5).toFixed(1))
        voltageDataX.shift()
        voltageDataX.push(axisData)
        chartVoltage.setOption({
          xAxis: [
            {
              data: voltageDataX
            }
          ],
          series: [
            {
              data: voltageDataY
            }
          ]
        })
      }, 6000)

      // 基于准备好的dom，初始化echarts 电流 实例
      var chartCurrent = echarts.init(
        document.getElementById('current'),
        'light'
      )
      const currentDataX = (function () {
        let now = new Date()
        const res = []
        let len = 10
        while (len--) {
          res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
          now = new Date(+now - 2000)
        }
        return res
      })()
      const currentDataY = (function () {
        const res = []
        let len = 0
        while (len < 10) {
          res.push(+(Math.random() * 10 + 5).toFixed(1))
          len++
        }
        return res
      })()
      // 绘制电流图表配置项third
      var optionSecond = {
        title: {
          text: '实时电流曲线' + '(' + this.dianliuTime + ')',
          textStyle: {
            color: '#1879b9'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: false
          },
          data: currentDataX
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            show: true
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        series: [
          {
            name: '电流',
            data: currentDataY,
            type: 'line',
            areaStyle: {}
          }
        ],
        color: [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgb(157, 241, 241)' // 0% 处的颜色
              },
              {
                offset: 0.5,
                color: 'rgb(157, 241, 241, 0.5)' // 50% 处的颜色
              },
              {
                offset: 1,
                color: 'rgb(157, 241, 241, 0.1)' // 100% 处的颜色
              }
            ]
          }
        ]
      }
      // 将配置项设置给echarts实例对象
      chartCurrent.setOption(optionSecond)
      setInterval(function () {
        const axisDataCurrent = new Date()
          .toLocaleTimeString()
          .replace(/^\D*/, '')
        currentDataY.shift()
        currentDataY.push(+(Math.random() * 10 + 5).toFixed(1))
        currentDataX.shift()
        currentDataX.push(axisDataCurrent)
        chartCurrent.setOption({
          xAxis: [
            {
              data: currentDataX
            }
          ],
          series: [
            {
              data: currentDataY
            }
          ]
        })
      }, 4000)

      // 基于准备好的dom，初始化echarts 温度 实例
      var chartTemp = echarts.init(document.getElementById('wendu'), 'light')
      const chartTempDataX = (function () {
        let now = new Date()
        const res = []
        let len = 10
        while (len--) {
          res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
          now = new Date(+now - 2000)
        }
        return res
      })()
      const chartTempDataY = (function () {
        const res = []
        let len = 0
        while (len < 100) {
          res.push(+(Math.random() * 50 + 20).toFixed(1))
          len++
        }
        return res
      })()
      // 绘制电流图表配置项third
      var optionThird = {
        title: {
          text: '实时温度曲线' + '(' + this.dianliuTime + ')',
          textStyle: {
            color: '#1879b9'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: false
          },
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: chartTempDataX
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisPointer: {
            // show: true
          },
          axisLine: {
            show: true
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        series: [
          {
            name: '温度',
            // data: [85, 29, 31, 34, 29, 30, 33],
            data: chartTempDataY,
            type: 'line',
            areaStyle: {}
          }
        ],
        visualMap: [
          {
            show: false,
            max: 90,
            min: 10
          }
        ]
      }
      // 将配置项设置给echarts实例对象
      chartTemp.setOption(optionThird)
      setInterval(function () {
        const axisDataChartTemp = new Date()
          .toLocaleTimeString()
          .replace(/^\D*/, '')
        chartTempDataY.shift()
        chartTempDataY.push(+(Math.random() * 50 + 20).toFixed(1))
        chartTempDataX.shift()
        chartTempDataX.push(axisDataChartTemp)
        chartTemp.setOption({
          xAxis: [
            {
              data: chartTempDataX
            }
          ],
          series: [
            {
              data: chartTempDataY
            }
          ]
        })
      }, 3000)
    },
    // 导出弹窗
    exportBtn() {
      this.exportEdit = true
    }
  }
})
</script>

<style lang="less" scoped>
.map-title {
  margin: 0 5px;
}
.map-value {
  color: #1879b9;
  font-weight: bold;
  margin-right: 5px;
}
.ud-body {
  background: #ffffff;
}
//
.timeSpan {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.bianhao {
  padding: 24px;

  .menu {
    // position: relative;
    padding: 0 0 10px 0;
  }
  .battery-img {
    position: relative;
    max-width: 220px;
    width: 100%;
    height: 80px;
    // margin: auto;
  }

  .header {
    position: absolute;
    width: 10px;
    height: 25px;
    right: 0%;
    top: 0;
    transform: translate(100%, 26px);
    border-radius: 0 5px 5px 0;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 0.5px 4px 5px rgba(120, 100, 100, 0.22);
  }

  .battery {
    max-width: 220px;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    border-radius: 10px 15px 15px 10px;
    box-shadow: 0px 0.5px 4px 5px rgba(120, 100, 100, 0.22);
    background: #fff;
    display: flex;
    justify-content: flex-end;
    z-index: 11;
    .battery-color {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      max-width: 220px;
      width: 100%;
      height: 80px;
      background: #fff;
      border-radius: 10px 15px 15px 10px;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 5px;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        max-width: 220px;
        width: calc(100% - 10px);
        height: 70px;
        z-index: 2;
        border-radius: 10px 15px 15px 10px;
        background: linear-gradient(
          to right,
          #ff1e00 0%,
          #ebf74e 50%,
          #02fc55 100%
        );
      }
    }
    .batteryNull {
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 0;
      max-width: 97%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 0px 15px 15px 0px;
      overflow: hidden;
      z-index: 99;
      &::after {
        content: '';
        position: absolute;
        top: 5px;
        height: 70px;
        width: 100%;
        background: #fff;
        border-radius: 0px 15px 15px 0px;
        box-sizing: border-box;
      }
    }
  }
}
.lis {
  display: flex;
  flex-direction: row;
}
.dianya {
  padding: 24px;
}
.title-span {
  color: #3b78d4;
  font-size: 20px;
  margin-bottom: 5px;
}
.color {
  color: #1879b9;
  font-size: 18px;
  font-weight: bold;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.battery-dianya {
  position: relative;
  // max-width: 60px;
  // width: 100%;
  width: 80px;
  height: 36px;
  border-radius: 5px;
  z-index: 99;
  box-shadow: 0px 0.5px 3px 3px rgba(120, 100, 100, 0.22);

  .battery-dianya-header {
    position: absolute;
    top: 5px;
    // left: 0;
    right: 0%;
    width: 5px;
    height: 12px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 2px 0.5px 3px 3px rgba(120, 100, 100, 0.22);
    border-radius: 0 2px 2px 0;
    transform: translate(100%, 5px);
    // z-index: 1;
  }
  .battery-dianya-body {
    position: absolute;
    top: 7%;
    left: 4%;
    box-sizing: border-box;
    width: 74px;
    height: 30px;
    background: #00afe4;
    color: #ffffff;
    border-radius: 5px;
    z-index: 0;
  }
}
#container {
  height: 100%;
}
</style>
